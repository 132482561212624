<template>
  <div class="settings-container-monitoring">
    <div class="settings-container-monitoring__top">
      <p>{{ $t('monitoring.map_work_settings') }}</p>
      <i
        @click="$emit('handlCloseSettingsPopup')"
        class="el-icon-close pointer monitoring__closeIcon monitoring__closeIcon-small"
      />
    </div>
    <div class="settings-container-monitoring__end">
      <div>
        <span class="subtitle">{{ $t('monitoring.objects_list') }}</span>
        <p class="info">
          {{ $t('monitoring.show_annotation_on_click') }}
        </p>
      </div>
      <skif-switch
        v-model="computedAnnotationSwitch"
        class="item-button annotation-switch"
        :is-big="true"
        @click.native.stop
        @change="$emit('changeObjectAnnotationSwitch')"
      />
    </div>
    <div class="settings-container-monitoring__end">
      <div>
        <p class="info">
          {{ $t('monitoring.show_address') }}
        </p>
      </div>
      <skif-switch
        v-model="computedAddressSwitch"
        class="item-button annotation-switch"
        :is-big="true"
        @click.native.stop
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SettingsMonitoring',
  props: {
    clusterRadiusCount: {
      type: Number,
      default: 100
    }
  },
  computed: {
    ...mapGetters({
      addressSwitch: 'monitoringNew/addressSwitch',
      objectAnnotationSwitch: 'units/objectAnnotationSwitch'
    }),

    computedAddressSwitch: {
      get() {
        return this.addressSwitch
      },
      set(val) {
        localStorage.setItem('addressSwitch', val)
        this.setAddressSwitch(val)
      }
    },
    computedAnnotationSwitch: {
      get() {
        return this.objectAnnotationSwitch
      },
      set(val) {
        this.setObjectAnnotationSwitch(val)
      }
    }
  },
  methods: {
    ...mapMutations({
      setAddressSwitch: 'monitoringNew/setAddressSwitch',
      setObjectAnnotationSwitch: 'units/SET_OBJECT_ANNOTATION_SWITCH'
    })
  }
}
</script>

<style lang="stylus" scoped>



.monitoring__closeIcon-small
  font-size 16px!important
  color #446CA7

.monitoring__closeIcon
  font-size 24px
  font-weight 600
  margin-left 20px
.settings-container-monitoring
  display flex
  flex-direction column

  &__top, &__middle, &__end
    display flex
    align-items center
    justify-content space-between

    .subtitle
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #828D9B;
      padding-top: 13px

    .annotation-switch
      width 40%
      display flex
      justify-content flex-end

  &__middle
    gap 8px

  .info
    font-size 16px
    margin-top 5px


  .input-container
    width 50%
    margin-top 15px
    display flex
    flex-direction column
    justify-content space-between
    align-items flex-end
.cluster-radius span
  position: relative;
  padding: 5px 15px;
  color: #5477a9;
  cursor: pointer;
  transition all 0.2s ease-in-out
  height 100%
  border-radius: 4px 0px 0px 4px
  &:hover
    background-color #446CA7
    color white
    &:first-child
      border-radius: 4px 0px 0px 4px
    &:last-child
      border-radius: 0px 4px 4px 0px

.cluster-radius
  width: fit-content;
  height: 33px;
  border: 1px solid #d1dbe4;
  border-radius: 5px;
  .radiusBtn:hover svg rect {
    fill: white
  }

  .cluster-radius-count
    margin 0 !important

.cluster-radius span:before
  content: '';
  position: absolute;
  top: -1px;
  width: 1px;
  height: 33px;
  background: #d1dbe4;

.cluster-radius span:first-child:before
  right: 0;

.cluster-radius span:last-child:before
  left: 0;

.cluster-radius p
  padding: 0 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;

.radiusSettings
  margin-left auto
.pointer
  cursor pointer
</style>
